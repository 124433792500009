import { FC, lazy, useCallback, useEffect, useState } from "react";

import { Header } from "./components/Header/Header";
import { Box } from "./components/ui/Box";
import { Footer } from "./components/unsorted/Footer";
import LazyLoadPage from "./pages/LazyLoadPage/LazyLoadPage";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Cookies from "js-cookie";

import userInfoApi from "./service/userApi/userInfoApi";

const AboutUsPage = lazy(() => import("./pages/AboutUsPage/AboutUsPage"));
const AccountVerificationPage = lazy(
  () => import("./pages/AccountVerificationPage/AccountVerificationPage")
);
const AddOwnPropertyPage = lazy(
  () => import("./pages/AddOwnPropertyPage/AddOwnPropertyPage")
);
const ApartmentPage = lazy(() => import("./pages/ApartmentPage/ApartmentPage"));
const BlogArticlePage = lazy(
  () => import("./pages/BlogArticlePage/BlogArticlePage")
);
const BlogPage = lazy(() => import("./pages/BlogPage/BlogPage"));
const EditMyOwnPropertiesPage = lazy(
  () => import("./pages/EditMyOwnPropertiesPage/EditMyOwnPropertiesPage")
);
const FAQPage = lazy(() => import("./pages/FAQPage/FAQPage"));
const ForgotPasswordPage = lazy(
  () => import("./pages/ForgotPasswordPage/ForgotPasswordPage")
);
const LoginPage = lazy(() => import("./pages/LoginPage/LoginPage"));
const MyAccountPage = lazy(() => import("./pages/MyAccountPage/MyAccountPage"));
const MyOwnPropertiesPage = lazy(
  () => import("./pages/MyOwnPropertiesPage/MyOwnPropertiesPage")
);
const MyRequestsPage = lazy(
  () => import("./pages/MyRequestsPage/MyRequestsPage")
);
const ReferencePage = lazy(() => import("./pages/ReferencePage/ReferencePage"));
const SearchPage = lazy(() => import("./pages/SearchPage/SearchPage"));
const SignUpPage = lazy(() => import("./pages/SignUpPage/SignUpPage"));
const SuccessPage = lazy(() => import("./pages/SuccessPage/SuccessPage"));
const VerifyPage = lazy(() => import("./pages/VerifyPage/VerifyPage"));

const App: FC = () => {
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userImage, setUserImage] = useState<string | null>("");
  const [userEmailStatus, setUserEmailStatus] = useState<boolean>(true);
  const [selfieVerificationStatus, setSelfieVerificationStatus] =
    useState<string>("uninitiated");

  const navigate = useNavigate();
  const location = useLocation();

  const sessionTokenStorage = localStorage.getItem("session_token");
  const sessionTokenCookies = Cookies.get("session_token");

  const getUserInfo = useCallback(async () => {
    try {
      const res = await userInfoApi.getUserInfo();
      setUserFirstName(res.data.first_name);
      setUserLastName(res.data.last_name);
      setUserImage(res.data.photo);
      setUserEmail(res.data.email);
      setUserEmailStatus(res.data.has_verified_email);
      setSelfieVerificationStatus(res.data.selfie_verification_status);
    } catch (error: unknown) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (sessionTokenCookies) {
      localStorage.setItem("session_token", sessionTokenCookies);
      if (localStorage.getItem("session_token")) {
        getUserInfo();
        return;
      }
      return;
    }
    if (sessionTokenStorage) {
      getUserInfo();
      if (
        (location.pathname.includes("/login") ||
          location.pathname.includes("/signup") ||
          location.pathname.includes("/forgot_password")) &&
        sessionTokenStorage !== null
      ) {
        navigate("/reference");
      }
      return;
    }
    if (
      location.pathname !== "/login" &&
      location.pathname !== "/signup" &&
      !location.pathname.includes("/forgot_password") &&
      !location.pathname.includes("/success") &&
      sessionTokenStorage === null
    ) {
      navigate("/login");
      return;
    }
  }, [
    sessionTokenCookies,
    sessionTokenStorage,
    navigate,
    location,
    getUserInfo,
  ]);

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {location.pathname !== "/login" &&
          location.pathname !== "/signup" &&
          !location.pathname.includes("/forgot_password") &&
          !location.pathname.includes("/success") &&
          location.pathname !== "/verify" && (
            <Header
              userImage={userImage !== null ? userImage : ""}
              userName={userFirstName}
              userLastName={userLastName}
              userEmailStatus={userEmailStatus}
            />
          )}
        <Routes>
          <Route path="/" element={<Navigate replace to={`/login`} />} />
          <Route
            path={`/signup`}
            element={<LazyLoadPage children={<SignUpPage />} />}
          />
          <Route
            path={`/forgot_password`}
            element={<LazyLoadPage children={<ForgotPasswordPage />} />}
          />
          <Route
            path={`/forgot_password/:passwordResetKey`}
            element={<LazyLoadPage children={<ForgotPasswordPage />} />}
          />
          <Route
            path={`/success`}
            element={<LazyLoadPage children={<SuccessPage />} />}
          />
          <Route
            path={`/reference`}
            element={
              <LazyLoadPage
                children={<ReferencePage userEmailStatus={userEmailStatus} />}
              />
            }
          />
          <Route
            path={`/search`}
            element={<LazyLoadPage children={<SearchPage />} />}
          />
          <Route
            path={`/search/:searchApartmentId`}
            element={
              <LazyLoadPage
                children={<ApartmentPage userEmailStatus={userEmailStatus} />}
              />
            }
          />
          <Route
            path={`/verify`}
            element={<LazyLoadPage children={<VerifyPage />} />}
          />
          <Route
            path={`/login`}
            element={<LazyLoadPage children={<LoginPage />} />}
          />
          <Route
            path={`/add-own-property`}
            element={<LazyLoadPage children={<AddOwnPropertyPage />} />}
          />
          <Route
            path={`/my-account`}
            element={
              <LazyLoadPage
                children={
                  <MyAccountPage
                    userImage={userImage ? userImage : ""}
                    userFirstName={userFirstName ? userFirstName : ""}
                    userLastName={userLastName ? userLastName : ""}
                    userEmail={userEmail ? userEmail : ""}
                    userEmailStatus={userEmailStatus}
                  />
                }
              />
            }
          />
          <Route
            path={`/account-verification`}
            element={
              <LazyLoadPage
                children={
                  <AccountVerificationPage
                    userImage={userImage ? userImage : ""}
                    userFirstName={userFirstName ? userFirstName : ""}
                    userLastName={userLastName ? userLastName : ""}
                    userEmail={userEmail ? userEmail : ""}
                    selfieVerificationStatus={selfieVerificationStatus}
                  />
                }
              />
            }
          />
          <Route
            path={`/my-own-property`}
            element={
              <LazyLoadPage
                children={
                  <MyOwnPropertiesPage
                    userImage={userImage ? userImage : ""}
                    userFirstName={userFirstName ? userFirstName : ""}
                    userLastName={userLastName ? userLastName : ""}
                    userEmail={userEmail ? userEmail : ""}
                    userEmailStatus={userEmailStatus}
                  />
                }
              />
            }
          />
          <Route
            path={`/my-own-property/:editApartmentId`}
            element={
              <LazyLoadPage
                children={
                  <EditMyOwnPropertiesPage
                    userImage={userImage ? userImage : ""}
                    userFirstName={userFirstName ? userFirstName : ""}
                    userLastName={userLastName ? userLastName : ""}
                    userEmail={userEmail ? userEmail : ""}
                    userEmailStatus={userEmailStatus}
                  />
                }
              />
            }
          />
          <Route
            path={`/my-requests`}
            element={
              <LazyLoadPage
                children={
                  <MyRequestsPage
                    userImage={userImage ? userImage : ""}
                    userFirstName={userFirstName ? userFirstName : ""}
                    userLastName={userLastName ? userLastName : ""}
                    userEmail={userEmail ? userEmail : ""}
                  />
                }
              />
            }
          />
          <Route
            path={`/blog`}
            element={<LazyLoadPage children={<BlogPage />} />}
          />
          <Route
            path={`/blog/:blogArticleId`}
            element={<LazyLoadPage children={<BlogArticlePage />} />}
          />
          <Route
            path={`/about-us`}
            element={<LazyLoadPage children={<AboutUsPage />} />}
          />
          <Route
            path={`/faq`}
            element={<LazyLoadPage children={<FAQPage />} />}
          />
        </Routes>
        {location.pathname !== "/login" &&
          location.pathname !== "/signup" &&
          !location.pathname.includes("/forgot_password") &&
          !location.pathname.includes("/success") &&
          location.pathname !== "/verify" && (
            <Footer userEmailStatus={userEmailStatus} />
          )}
      </ThemeProvider>
    </Box>
  );
};

export default App;
