import { basicTheme } from "../../../theme";
import { SxStyles } from "../../../types/styles";

export const styles: SxStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: 4,
  },
  listItem: {
    p: 0,
  },
};
