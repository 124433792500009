import React, { FC } from "react";

import { UnVerifiedIcon } from "../../icons/UnVerifiedIcon";
import { VerifiedIcon } from "../../icons/VerifiedIcon";
import { Box } from "../../ui/Box";
import { Typography } from "../../ui/Typography";

import { styles } from "./styles";
import { SxProps } from "@mui/material";
import Image from "mui-image";
import userPlaceholder from "../../../assets/images/userPlaceholder.png";

export interface UserImageProps {
  src?: null | string;
}

export const UserImage: FC<UserImageProps> = React.memo(({ src }) => {
  return (
    <Box sx={styles.root}>
      <Image
        src={src && src !== null ? src : userPlaceholder}
        duration={0}
        fit="cover"
      />
    </Box>
  );
});
