import { FC } from "react";

import { SvgIconProps } from "../../../types/styles";
import { SvgIcon } from "../../../components/ui/SvgIcon";

export const LogoutIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.50195 5.83335C7.51204 4.02083 7.59242 3.03924 8.23274 2.39892C8.96497 1.66669 10.1435 1.66669 12.5005 1.66669L13.3338 1.66669C15.6909 1.66669 16.8694 1.66669 17.6016 2.39892C18.3338 3.13115 18.3338 4.30966 18.3338 6.66669L18.3338 13.3334C18.3338 15.6904 18.3338 16.8689 17.6016 17.6011C16.8694 18.3334 15.6909 18.3334 13.3338 18.3334L12.5005 18.3334C10.1435 18.3334 8.96497 18.3334 8.23274 17.6011C7.59242 16.9608 7.51204 15.9792 7.50195 14.1667"
      stroke="#1B2E3C"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M7.50195 5.83335C7.51204 4.02083 7.59242 3.03924 8.23274 2.39892C8.96497 1.66669 10.1435 1.66669 12.5005 1.66669L13.3338 1.66669C15.6909 1.66669 16.8694 1.66669 17.6016 2.39892C18.3338 3.13115 18.3338 4.30966 18.3338 6.66669L18.3338 13.3334C18.3338 15.6904 18.3338 16.8689 17.6016 17.6011C16.8694 18.3334 15.6909 18.3334 13.3338 18.3334L12.5005 18.3334C10.1435 18.3334 8.96497 18.3334 8.23274 17.6011C7.59242 16.9608 7.51204 15.9792 7.50195 14.1667"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M7.50195 5.83335C7.51204 4.02083 7.59242 3.03924 8.23274 2.39892C8.96497 1.66669 10.1435 1.66669 12.5005 1.66669L13.3338 1.66669C15.6909 1.66669 16.8694 1.66669 17.6016 2.39892C18.3338 3.13115 18.3338 4.30966 18.3338 6.66669L18.3338 13.3334C18.3338 15.6904 18.3338 16.8689 17.6016 17.6011C16.8694 18.3334 15.6909 18.3334 13.3338 18.3334L12.5005 18.3334C10.1435 18.3334 8.96497 18.3334 8.23274 17.6011C7.59242 16.9608 7.51204 15.9792 7.50195 14.1667"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M7.50195 5.83335C7.51204 4.02083 7.59242 3.03924 8.23274 2.39892C8.96497 1.66669 10.1435 1.66669 12.5005 1.66669L13.3338 1.66669C15.6909 1.66669 16.8694 1.66669 17.6016 2.39892C18.3338 3.13115 18.3338 4.30966 18.3338 6.66669L18.3338 13.3334C18.3338 15.6904 18.3338 16.8689 17.6016 17.6011C16.8694 18.3334 15.6909 18.3334 13.3338 18.3334L12.5005 18.3334C10.1435 18.3334 8.96497 18.3334 8.23274 17.6011C7.59242 16.9608 7.51204 15.9792 7.50195 14.1667"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M12.5 10L1.66667 10M1.66667 10L4.58333 7.5M1.66667 10L4.58333 12.5"
      stroke="#1B2E3C"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 10L1.66667 10M1.66667 10L4.58333 7.5M1.66667 10L4.58333 12.5"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 10L1.66667 10M1.66667 10L4.58333 7.5M1.66667 10L4.58333 12.5"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 10L1.66667 10M1.66667 10L4.58333 7.5M1.66667 10L4.58333 12.5"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
