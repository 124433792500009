import { SxStyles } from "../../../types/styles";
import { basicTheme } from "../../../theme";

export const styles: SxStyles = {
  root: {
    width: "100%",
    height: "100%",
    borderRadius: "100%",
    aspectRatio: "1 / 1",
    overflow: "hidden",
    img: {
      borderRadius: "100% !important",
    },
  },
};
