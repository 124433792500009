import { createTheme } from "@mui/material";

const PoppinsFontFamily = "'Poppins', Arial, serif";

export const basicTheme = createTheme({
  palette: {
    background: {
      default: "transparent",
    },
    custom: {
      white: "#FFFFFF",
      black: "#1B2E3C",
      gray: "#8D969D",
      grayLight: "#D1D5D8",
      yellow: "#DBFE0A",
      beige: "#F5F2D5",
      red: "#FD736E",
      pink: "#FFEBEA",
      orange: "#FFA133",
    },
    greenPalette: {
      main: "#023202",
      additional: "#35E18F",
      light: "#E9FFF4",
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 744,
      laptop: 1272,
      desktop: 1440,
    },
  },
  typography: {
    fontFamily: [PoppinsFontFamily].join(","),
    h1: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 700,
      fontSize: "64px",
    },
    h2: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 500,
      fontSize: "48px",
      lineHeight: "72px",
    },
    h3: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 500,
      fontSize: "32px",
      lineHeight: "48px",
    },
    h4: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 500,
      fontSize: "36px",
      lineHeight: "54px",
    },
    h5: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 500,
      fontSize: "28px",
      lineHeight: "42px",
    },
    text24Medium: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "36px",
    },
    text20Medium: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "30px",
    },
    text20Regular: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "30px",
    },
    text18Regular: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "27px",
    },
    text18Bold: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "27px",
    },
    text18Medium: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "27px",
    },
    text16Bold: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
    },
    text16Medium: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
    },
    text16Regular: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
    text14Bold: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "21px",
    },
    text14Semibold: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "21px",
    },
    text14Regular: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "21px",
    },
    text12Regular: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
    },
    textButton: {
      fontFamily: PoppinsFontFamily,
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "27px",
    },
  },
  spacing: 4,
});

export const theme = createTheme(
  {
    components: {
      MuiTypography: {
        defaultProps: {
          variant: "text16Regular",
          color: "#1B2E3C",
          variantMapping: {
            h1: "h1",
            h2: "h2",
            h3: "h3",
            h4: "h4",
            h5: "h5",
            h6: "h6",
            subtitle1: "h2",
            subtitle2: "h2",
            text24Medium: "p",
            text20Medium: "p",
            text20Regular: "p",
            text18Bold: "p",
            text18Medium: "p",
            text18Regular: "p",
            text16Bold: "p",
            text16Medium: "p",
            text16Regular: "p",
            text14Bold: "p",
            text14Semibold: "p",
            text14Regular: "p",
            text12Regular: "p",
            textButton: "p",
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: "laptop",
        },
        styleOverrides: {
          root: {
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: 0,
            textTransform: "none",
            color: "inherit",
            "&[disabled]": {
              color: "#8D969D",
              backgroundColor: "#D1D5D8 ",
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecorationColor: "unset",
            textDecorationThickness: 1,
            textUnderlineOffset: 1.5,
            "&:disabled": {
              color: "grayLight",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            color: "custom.black",
            ".MuiFormLabel-root": {
              color: "#1B2E3C !important",
              marginLeft: 3,
              typography: "text14Regular",
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#1B2E3C !important",
              borderRadius: "8px !important",
              borderWidth: "1px !important",
            },
            ".css-14lo706": {
              marginLeft: 3,
            },
            ".MuiFormLabel-asterisk": {
              color: "#FD736E",
            },
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          root: {
            ".MuiPaginationItem-root": {
              borderColor: "#D1D5D8 !important",
              marginLeft: 5,
              marginRight: 5,
            },
            ".Mui-selected": {
              backgroundColor: "#DBFE0A !important",
              border: 0,
            },
            ".css-1im8ngo-MuiPaginationItem-root": {
              border: "1px solid #D1D5D8",
              padding: "5px",
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            ".MuiSlider-thumb": {
              backgroundColor: "#DBFE0A !important",
              color: "#DBFE0A !important",
            },
            color: "#1B2E3C",
            height: "1px",
          },
        },
      },
    },
  },
  basicTheme
);
