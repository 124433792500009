import { FC } from "react";

import { SvgIconProps } from "../../../types/styles";
import { SvgIcon } from "../../../components/ui/SvgIcon";

export const MessageIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.16602 37.0002C6.16602 25.3722 6.16602 19.5582 9.77837 15.9458C13.3907 12.3335 19.2047 12.3335 30.8327 12.3335H43.166C54.794 12.3335 60.608 12.3335 64.2203 15.9458C67.8327 19.5582 67.8327 25.3722 67.8327 37.0002C67.8327 48.6281 67.8327 54.4421 64.2203 58.0545C60.608 61.6668 54.794 61.6668 43.166 61.6668H30.8327C19.2047 61.6668 13.3907 61.6668 9.77837 58.0545C6.16602 54.4421 6.16602 48.6281 6.16602 37.0002Z"
      stroke="#023202"
      strokeWidth="3"
      fill="none"
    />
    <path
      d="M18.5 24.6665L25.1566 30.2137C30.8196 34.9328 33.651 37.2924 37 37.2924C40.349 37.2924 43.1804 34.9328 48.8434 30.2137L55.5 24.6665"
      stroke="#023202"
      strokeWidth="3"
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
);
