import { FC } from "react";

import { SvgIconProps } from "../../../types/styles";
import { SvgIcon } from "../../../components/ui/SvgIcon";

export const SuccessIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 162 162"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="81" cy="81" r="81" fill="#E9FFF4" />
    <circle
      cx="81.0003"
      cy="81"
      r="45.8333"
      stroke="#35E18F"
      strokeWidth="6"
      fill="none"
    />
    <path
      d="M64.958 83.2917L74.1247 92.4583L97.0413 69.5417"
      stroke="#35E18F"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
