import { FC, useState } from "react";

import { AppLogo } from "../../../app/AppLogo";
import { Button } from "../../Buttons/Button";
import { AccountIcon } from "../../icons/AccountIcon";
import { ArrowBottomIcon } from "../../icons/ArrowBottomIcon";
import { ArrowTopIcon } from "../../icons/ArrowTopIcon";
import { LogoutIcon } from "../../icons/LogoutIcon";
import { Box } from "../../ui/Box";
import { Container } from "../../ui/Container";
import { UserImage } from "../../unsorted/UserImage";
import { VerifyPopup } from "../../Verification/VerifyPopup";
import { HeaderMenu } from "../HeaderMenu";
import { HeaderMobile } from "../HeaderMobile";

import { styles } from "./styles";

import { useBreakpoints } from "../../../hooks/useBreakpoints";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import { useNavigate } from "react-router-dom";

export interface HeaderProps {
  variant?: "primary" | "secondary";
  userImage: string;
  userName: string;
  userLastName: string;
  userEmailStatus: boolean;
}

export const Header: FC<HeaderProps> = ({
  variant = "primary",
  userImage,
  userName,
  userLastName,
  userEmailStatus,
}) => {
  const { isUpTablet } = useBreakpoints();
  const navigate = useNavigate();
  const [openVerifyPopup, setOpenVerifyPopup] = useState(false);

  const [openMenuDropdown, setOpenMenuDropdown] = useState(false);
  const menuList = [
    {
      icon: <AccountIcon size="md-ter" sx={{ color: "transparent" }} />,
      text: "My account",
      link: "#/my-account",
      id: 0,
      type: "link" as "link",
    },
    {
      icon: <LogoutIcon size="md-ter" sx={{ color: "transparent" }} />,
      text: "Logout",
      link: "",
      id: 4,
      type: "logout" as "logout",
    },
  ];
  const handleAddOwnPropertyButton = () => {
    if (!userEmailStatus) {
      setOpenVerifyPopup(true);
      setOpenMenuDropdown(false)
    } else {
      setOpenMenuDropdown(false)
      navigate("/add-own-property");
    }
  };
  return (
    <Box sx={styles.root}>
      {variant === "primary" ? (
        <Container sx={styles.container}>
          {isUpTablet ? (
            <Box sx={styles.showHead}>
              <Box
                sx={styles.logo}
                onClick={() => {
                  navigate("/reference");
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <AppLogo />
              </Box>
              <ClickAwayListener onClickAway={() => setOpenMenuDropdown(false)}>
                <Box sx={styles.userInfo}>
                  <Box
                    onClick={() => setOpenMenuDropdown(!openMenuDropdown)}
                    sx={styles.userInfoMenu}
                  >
                    <Box sx={styles.userImage}>
                      <UserImage src={userImage} />
                    </Box>
                    {openMenuDropdown ? <ArrowTopIcon /> : <ArrowBottomIcon />}
                  </Box>
                  <Button
                    text="Add your property"
                    variantType="secondary"
                    onClick={handleAddOwnPropertyButton}
                  />
                  {openVerifyPopup && !userEmailStatus && (
                    <VerifyPopup
                      open={openVerifyPopup}
                      setOpen={setOpenVerifyPopup}
                      title="Please verify your email"
                      subtitle="You can't add own property until you verify your email."
                      buttonText="Verify"
                      buttonVariant="send"
                    />
                  )}
                  {openMenuDropdown && (
                    <Box sx={styles.headerDropDown}>
                      <HeaderMenu
                        userImage={userImage}
                        userName={userName}
                        userLastName={userLastName}
                        menuList={menuList}
                        setOpenMenu={setOpenMenuDropdown}
                      />
                    </Box>
                  )}
                </Box>
              </ClickAwayListener>
            </Box>
          ) : (
            <HeaderMobile
              userImage={userImage}
              userName={userName}
              userLastName={userLastName}
              userEmailStatus={userEmailStatus}
            />
          )}
        </Container>
      ) : (
        <Box sx={styles.logoSecondary}>
          <AppLogo />
        </Box>
      )}
    </Box>
  );
};
