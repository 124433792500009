import { basicTheme } from "../../../theme";
import { SxStyles } from "../../../types/styles";

export const styles: SxStyles = {
  root: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    height: "40px",
    width: "100%",
    my: "auto",
  },
  logo: {
    width: 147,
    height: 40,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  drawerRoot: {},
  drawer: {
    width: 312,
    py: 6,
    px: 4,
  },
  drawerLogo: {
    width: 147,
    height: 40,
  },
  drawerHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 6,
  },
  userImage: {
    width: 50,
    height: 50,
  },
  userContainer: {
    display: "flex",
    columnGap: 2,
    alignItems: "center",
  },
  divider: {
    my: 4,
  },
  button: {
    width: "100%",
    mt: 4,
  },
};
