import { FC, useState } from "react";

import { AppLogo } from "../../../app/AppLogo";
import { Button } from "../../Buttons/Button";
import { AccommodationIcon } from "../../icons/AccommodationIcon";
import { AccountIcon } from "../../icons/AccountIcon";
import { CrossIcon } from "../../icons/CrossIcon";
import { LogoutIcon } from "../../icons/LogoutIcon";
import { MenuIcon } from "../../icons/MenuIcon";
import { RequestIcon } from "../../icons/RequestIcon";
import { VerificationIcon } from "../../icons/VerificationIcon";
import { Box } from "../../ui/Box";
import { Divider } from "../../ui/Divider";
import { Drawer } from "../../ui/Drawer";
import { IconButton } from "../../ui/IconButton";
import { Typography } from "../../ui/Typography";
import { UserImage } from "../../unsorted/UserImage";
import { VerifyPopup } from "../../Verification/VerifyPopup";
import { HeaderMobileMenuList } from "../HeaderMobileMenuList";

import { styles } from "./styles";

import { useNavigate } from "react-router-dom";

export interface HeaderMobileProps {
  userImage: string;
  userName: string;
  userLastName: string;
  userEmailStatus: boolean;
}

export const HeaderMobile: FC<HeaderMobileProps> = ({
  userImage,
  userName,
  userLastName,
  userEmailStatus,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const [openVerifyPopup, setOpenVerifyPopup] = useState(false);

  const menuList = [
    {
      icon: <AccountIcon size="md-ter" sx={{ color: "transparent" }} />,
      text: "My account",
      link: "#/my-account",
      id: 0,
      type: "link" as "link",
    },
    {
      icon: <VerificationIcon size="md-ter" sx={{ color: "transparent" }} />,
      text: "Verification",
      link: "#/account-verification",
      id: 1,
      type: "link" as "link",
    },
    {
      icon: <AccommodationIcon size="md-ter" sx={{ color: "transparent" }} />,
      text: "My own property",
      link: "#/my-own-property",
      id: 2,
      type: "link" as "link",
    },
    {
      icon: <RequestIcon size="md-ter" sx={{ color: "transparent" }} />,
      text: "My requests",
      link: "#/my-requests",
      id: 3,
      type: "link" as "link",
    },
    {
      icon: <LogoutIcon size="md-ter" sx={{ color: "transparent" }} />,
      text: "Logout",
      link: "",
      id: 4,
      type: "logout" as "logout",
    },
  ];

  const handleAddOwnPropertyButton = () => {
    if (!userEmailStatus) {
      setOpenVerifyPopup(true);
      setOpenDrawer(false);
    } else {
      navigate("/add-own-property");
      setOpenDrawer(false);
    }
  };

  return (
    <Box sx={styles.root}>
      <IconButton onClick={() => setOpenDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Box sx={styles.logo} onClick={() => navigate("/reference")}>
        <AppLogo />
      </Box>
      {openDrawer && (
        <Drawer
          sx={styles.drawerRoot}
          anchor="left"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <Box sx={styles.drawer}>
            <Box sx={styles.drawerHead}>
              <Box sx={styles.drawerLogo}>
                <AppLogo />
              </Box>
              <IconButton onClick={() => setOpenDrawer(false)}>
                <CrossIcon />
              </IconButton>
            </Box>
            <Box sx={styles.userContainer}>
              <Box sx={styles.userImage}>
                <UserImage src={userImage} />
              </Box>
              <Typography>
                {userName} {userLastName}
              </Typography>
            </Box>
            <Divider sx={styles.divider} />
            <HeaderMobileMenuList
              menuList={menuList}
              setOpenMenu={setOpenDrawer}
            />
            <Button
              text="Add your property"
              variantType="secondary"
              sx={styles.button}
              onClick={handleAddOwnPropertyButton}
            />
          </Box>
        </Drawer>
      )}
      {openVerifyPopup && !userEmailStatus && (
        <VerifyPopup
          open={openVerifyPopup}
          setOpen={setOpenVerifyPopup}
          title="Please verify your email"
          subtitle="You can't add own property until you verify your email."
          buttonText="Verify"
          buttonVariant="send"
        />
      )}
    </Box>
  );
};
