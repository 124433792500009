import { SxStyles } from "../../../types/styles";

export const styles: SxStyles = {
  button: {
    p: 4,
    borderRadius: 2,
    border: "1px solid #1B2E3C",
    "&:hover": {
      bgcolor: "custom.white",
    },
  },
  buttonPrimary: {
    p: 4,
    borderRadius: 2,
    bgcolor: "custom.yellow",
    border: "1px solid transparent",
    "&:hover": {
      bgcolor: "custom.yellow",
    },
  },
  buttonWhite: {
    bgcolor: "custom.white",
    "&:hover": {
      bgcolor: "custom.white",
    },
  },
};
