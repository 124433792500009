import { basicTheme } from "../../../theme";
import { SxStyles } from "../../../types/styles";

export const styles: SxStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: 4,
  },
  userImage: {
    width: 50,
    height: 50,
  },
  userContainer: {
    display: "flex",
    columnGap: 2,
    alignItems: "center",
  },
  divider: {
    my: 4,
  },
  listItem: {
    p: 0,
  },
};
