import { FC } from "react";

import { SvgIconProps } from "../../../types/styles";
import { SvgIcon } from "../../../components/ui/SvgIcon";

export const MenuIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 7L4 7" stroke="#1B2E3C" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20 7L4 7" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20 7L4 7" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20 7L4 7" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20 12L4 12" stroke="#1B2E3C" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20 12L4 12" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20 12L4 12" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20 12L4 12" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20 17L4 17" stroke="#1B2E3C" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20 17L4 17" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20 17L4 17" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M20 17L4 17" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round"/>
  </SvgIcon>
);
