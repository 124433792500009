import { basicTheme } from "../../../theme";
import { SxStyles } from "../../../types/styles";

export const styles: SxStyles = {
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mt: 24,
    mb: 10,
    minHeight: "calc(100vh - 409px - 72px)",
    [basicTheme.breakpoints.up("tablet")]: {
      mt: 29,
      mb: 12,
      minHeight: "calc(100vh - 273px - 91px)",
    },
  },
  loader: {
    color: "custom.black",
  },
};
