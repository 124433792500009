import { FC } from "react";

import { AppLogo } from "../../../app/AppLogo";
import { EmpatLogo } from "../../../app/EmpatLogo";
import { Box } from "../../ui/Box";
import { Container } from "../../ui/Container";
import { Divider } from "../../ui/Divider";
import { Link } from "../../ui/Link";
import { Typography } from "../../ui/Typography";

import { styles } from "./styles";
import { SxProps } from "@mui/material";

import { useLocation } from "react-router-dom";

export interface FooterProps {
  userEmailStatus: boolean;
}

export const Footer: FC<FooterProps> = ({ userEmailStatus }) => {
  const location = useLocation();

  const rootStyle = (locationPath: string, verifiedEmail: boolean): SxProps => {
    if (locationPath.includes("search") && !verifiedEmail) {
      return { ...styles.root, ...styles.rootUnverified } as SxProps;
    }
    if (locationPath.includes("search") && verifiedEmail) {
      return { ...styles.root, ...styles.rootVerified } as SxProps;
    }
    return styles.root;
  };

  return (
    <Box sx={rootStyle(location.pathname, userEmailStatus)}>
      <Container sx={styles.container}>
        <Box sx={styles.partContainer}>
          <Link
            sx={styles.nyLogo}
            href="https://www.ny-stays.com/"
            target="_blank"
          >
            <AppLogo variant="white" />
          </Link>
          <Box sx={styles.linkContainer}>
            <Link
              href="#/about-us"
              sx={styles.link}
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
            >
              <Typography
                variant="text16Bold"
                color={
                  location.pathname.includes("about-us")
                    ? "custom.yellow"
                    : "custom.white"
                }
              >
                About us
              </Typography>
            </Link>
            <Link
              href="#/faq"
              sx={styles.link}
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
            >
              <Typography
                variant="text16Bold"
                color={
                  location.pathname.includes("faq")
                    ? "custom.yellow"
                    : "custom.white"
                }
              >
                FAQ
              </Typography>
            </Link>
            <Link
              href="#/blog"
              sx={styles.link}
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
            >
              <Typography
                variant="text16Bold"
                color={
                  location.pathname.includes("blog")
                    ? "custom.yellow"
                    : "custom.white"
                }
              >
                Blog
              </Typography>
            </Link>
          </Box>
        </Box>
        <Divider sx={styles.divider} />
        <Box sx={styles.partContainer}>
          <Link
            sx={styles.empatLogo}
            href="https://empat.tech/"
            target="_blank"
          >
            <EmpatLogo />
          </Link>
          <Typography color="custom.white" sx={styles.rightsReserved}>
            © 2023 All Rights Reserved
          </Typography>
          <Link
            href="https://api.ny-stays.com/pages/privacy"
            target="_blank"
            sx={styles.privacyLink}
          >
            <Typography color="custom.white">PRIVACY POLICY</Typography>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};
