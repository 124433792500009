import { FC } from "react";

import { SvgIconProps } from "../../../types/styles";
import { SvgIcon } from "../../ui/SvgIcon";

export const AccommodationIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3327 18.3333L1.66602 18.3333"
      stroke="#1B2E3C"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M18.3327 18.3333L1.66602 18.3333"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M18.3327 18.3333L1.66602 18.3333"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M18.3327 18.3333L1.66602 18.3333"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M1.66602 9.16667L8.43761 3.7494C9.35066 3.01896 10.648 3.01896 11.5611 3.7494L18.3327 9.16667"
      stroke="#1B2E3C"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M1.66602 9.16667L8.43761 3.7494C9.35066 3.01896 10.648 3.01896 11.5611 3.7494L18.3327 9.16667"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M1.66602 9.16667L8.43761 3.7494C9.35066 3.01896 10.648 3.01896 11.5611 3.7494L18.3327 9.16667"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M1.66602 9.16667L8.43761 3.7494C9.35066 3.01896 10.648 3.01896 11.5611 3.7494L18.3327 9.16667"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M12.916 4.58333V2.91667C12.916 2.68655 13.1026 2.5 13.3327 2.5H15.416C15.6461 2.5 15.8327 2.68655 15.8327 2.91667V7.08333"
      stroke="#1B2E3C"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M12.916 4.58333V2.91667C12.916 2.68655 13.1026 2.5 13.3327 2.5H15.416C15.6461 2.5 15.8327 2.68655 15.8327 2.91667V7.08333"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M12.916 4.58333V2.91667C12.916 2.68655 13.1026 2.5 13.3327 2.5H15.416C15.6461 2.5 15.8327 2.68655 15.8327 2.91667V7.08333"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M12.916 4.58333V2.91667C12.916 2.68655 13.1026 2.5 13.3327 2.5H15.416C15.6461 2.5 15.8327 2.68655 15.8327 2.91667V7.08333"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M3.33398 18.3333V7.91666"
      stroke="#1B2E3C"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M3.33398 18.3333V7.91666"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M3.33398 18.3333V7.91666"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M3.33398 18.3333V7.91666"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M16.666 18.3333V7.91666"
      stroke="#1B2E3C"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M16.666 18.3333V7.91666"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M16.666 18.3333V7.91666"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M16.666 18.3333V7.91666"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M12.5 18.3333V14.1667C12.5 12.9881 12.5 12.3989 12.1339 12.0328C11.7678 11.6667 11.1785 11.6667 10 11.6667C8.82149 11.6667 8.23223 11.6667 7.86612 12.0328C7.5 12.3989 7.5 12.9881 7.5 14.1667V18.3333"
      stroke="#1B2E3C"
      strokeWidth="1.25"
    />
    <path
      d="M12.5 18.3333V14.1667C12.5 12.9881 12.5 12.3989 12.1339 12.0328C11.7678 11.6667 11.1785 11.6667 10 11.6667C8.82149 11.6667 8.23223 11.6667 7.86612 12.0328C7.5 12.3989 7.5 12.9881 7.5 14.1667V18.3333"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
    />
    <path
      d="M12.5 18.3333V14.1667C12.5 12.9881 12.5 12.3989 12.1339 12.0328C11.7678 11.6667 11.1785 11.6667 10 11.6667C8.82149 11.6667 8.23223 11.6667 7.86612 12.0328C7.5 12.3989 7.5 12.9881 7.5 14.1667V18.3333"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
    />
    <path
      d="M12.5 18.3333V14.1667C12.5 12.9881 12.5 12.3989 12.1339 12.0328C11.7678 11.6667 11.1785 11.6667 10 11.6667C8.82149 11.6667 8.23223 11.6667 7.86612 12.0328C7.5 12.3989 7.5 12.9881 7.5 14.1667V18.3333"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
    />
    <path
      d="M11.6673 7.91667C11.6673 8.83714 10.9211 9.58333 10.0007 9.58333C9.08018 9.58333 8.33398 8.83714 8.33398 7.91667C8.33398 6.99619 9.08018 6.25 10.0007 6.25C10.9211 6.25 11.6673 6.99619 11.6673 7.91667Z"
      stroke="#1B2E3C"
      strokeWidth="1.25"
    />
    <path
      d="M11.6673 7.91667C11.6673 8.83714 10.9211 9.58333 10.0007 9.58333C9.08018 9.58333 8.33398 8.83714 8.33398 7.91667C8.33398 6.99619 9.08018 6.25 10.0007 6.25C10.9211 6.25 11.6673 6.99619 11.6673 7.91667Z"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
    />
    <path
      d="M11.6673 7.91667C11.6673 8.83714 10.9211 9.58333 10.0007 9.58333C9.08018 9.58333 8.33398 8.83714 8.33398 7.91667C8.33398 6.99619 9.08018 6.25 10.0007 6.25C10.9211 6.25 11.6673 6.99619 11.6673 7.91667Z"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
    />
    <path
      d="M11.6673 7.91667C11.6673 8.83714 10.9211 9.58333 10.0007 9.58333C9.08018 9.58333 8.33398 8.83714 8.33398 7.91667C8.33398 6.99619 9.08018 6.25 10.0007 6.25C10.9211 6.25 11.6673 6.99619 11.6673 7.91667Z"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
    />
  </SvgIcon>
);
