import axios from "axios";
import { apiBaseUrl, apiHeader } from "../consts";

const userInfoApi = {
  getUserInfo: async () => {
    try {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/api/user`,
        headers: apiHeader,
      };
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getUserApartments: async (perPage: number, page: number) => {
    try {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/api/user/apartments?per_page=${perPage}&page=${page}`,
        headers: apiHeader,
      };
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteApartment: async (apartmentId: number) => {
    try {
      const config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/api/apartments/${apartmentId}/`,
        headers: apiHeader,
      };
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default userInfoApi;
