import { FC, ReactNode } from "react";

import { List } from "../../ui/List";
import { ListItem } from "../../ui/ListItem";
import { HeaderMobileMenuListItem } from "../HeaderMobileMenuListItem";

import { styles } from "./styles";

export interface HeaderMobileMenuListProps {
  menuList: {
    icon: ReactNode;
    text: string;
    link: string;
    id: number;
    type: "link" | "logout";
  }[];
  setOpenMenu?: (value: React.SetStateAction<boolean>) => void;
}

export const HeaderMobileMenuList: FC<HeaderMobileMenuListProps> = ({
  menuList,
  setOpenMenu,
}) => {
  return (
    <List sx={styles.root}>
      {menuList.map(({ icon, text, link, id, type }) => (
        <ListItem
          key={id}
          sx={styles.listItem}
          onClick={() => setOpenMenu && setOpenMenu(false)}
        >
          <HeaderMobileMenuListItem
            icon={icon}
            text={text}
            link={link}
            type={type}
          />
        </ListItem>
      ))}
    </List>
  );
};
