import { basicTheme } from "../../../theme";
import { SxStyles } from "../../../types/styles";

export const styles: SxStyles = {
  root: {
    width: "100%",
    py: 10,
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #1b2e3c",
    color: "custom.white",
    [basicTheme.breakpoints.up("tablet")]: {
      py: 20,
    },
  },
  rootUnverified: {
    pb: 67,
  },
  rootVerified: {
    pb: 35,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: 6,
  },
  partContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: 4,
    [basicTheme.breakpoints.up("tablet")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  linkContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: 4,
    columnGap: 4,
    alignItems: "center",
    color: "custom.white",
    [basicTheme.breakpoints.up("tablet")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  nyLogo: {
    width: 147,
    height: 40,
    mb: 4,
    [basicTheme.breakpoints.up("tablet")]: {
      mb: 0,
    },
  },
  empatLogo: {
    width: 96,
    height: 24,
    order: 2,
    [basicTheme.breakpoints.up("tablet")]: {
      order: 0,
    },
  },
  privacyLink: {
    order: 0,
    textDecoration: "none",
    [basicTheme.breakpoints.up("tablet")]: {
      order: 2,
    },
  },
  rightsReserved: {
    order: 1,
    [basicTheme.breakpoints.up("tablet")]: {
      order: 1,
    },
  },
  divider: {
    bgcolor: "custom.white",
  },
  link: {
    textDecoration: "none",
  },
};
