import axios from "axios";
import { apiBaseUrl, apiHeader } from "../consts";

const verificationApi = {
  verifySelfie: async (data: FormData) => {
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/api/user/selfie-verification`,
        headers: apiHeader,
        data: data,
      };
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  verifyEmail: async () => {
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/api/auth/verification/email`,
        headers: apiHeader,
      };
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default verificationApi;
