import { FC, ReactNode } from "react";

import { Box } from "../../ui/Box";
import { Divider } from "../../ui/Divider";
import { Typography } from "../../ui/Typography";
import { UserImage } from "../../unsorted/UserImage";
import { HeaderMobileMenuList } from "../HeaderMobileMenuList";

import { styles } from "./styles";

export interface HeaderMenuProps {
  userImage: string;
  userName: string;
  userLastName: string;
  menuList: {
    icon: ReactNode;
    text: string;
    link: string;
    id: number;
    type: "link" | "logout";
  }[];
  setOpenMenu: (value: React.SetStateAction<boolean>) => void;
}

export const HeaderMenu: FC<HeaderMenuProps> = ({
  userImage,
  userName,
  userLastName,
  menuList,
  setOpenMenu,
}) => {
  return (
    <Box>
      <Box sx={styles.userContainer}>
        <Box sx={styles.userImage}>
          <UserImage src={userImage} />
        </Box>
        <Typography>
          {userName} {userLastName}
        </Typography>
      </Box>
      <Divider sx={styles.divider} />
      <HeaderMobileMenuList menuList={menuList} setOpenMenu={setOpenMenu}/>
    </Box>
  );
};
