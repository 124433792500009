import { FC, ReactNode } from "react";

import { ArrowRightIcon } from "../../icons/ArrowRightIcon";
import { Box } from "../../ui/Box";
import { Link } from "../../ui/Link";
import { Typography } from "../../ui/Typography";

import { styles } from "./styles";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export interface HeaderMobileMenuListItemProps {
  icon: ReactNode;
  text: string;
  link: string;
  type?: "link" | "logout";
}

export const HeaderMobileMenuListItem: FC<HeaderMobileMenuListItemProps> = ({
  icon,
  text,
  link,
  type = "link",
}) => {
  const navigate = useNavigate();

  return (
    <>
      {type === "link" ? (
        <Link
          sx={styles.root}
          href={link}
          onClick={() =>
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
        >
          <Box sx={styles.textContainer}>
            {icon}
            <Typography>{text}</Typography>
          </Box>
          <ArrowRightIcon size="md-ter" />
        </Link>
      ) : (
        <Box
          sx={styles.root}
          onClick={() => {
            localStorage.removeItem("session_token");
            Cookies.remove("session_token");
            navigate("/login");
          }}
        >
          <Box sx={styles.textContainer}>
            {icon}
            <Typography>Logout</Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
