import { basicTheme } from "../../../theme";
import { SxStyles } from "../../../types/styles";

export const styles: SxStyles = {
  root: {
    ".MuiPaper-root": {
      borderRadius: 4,
      mx: 4,
    },
  },
  container: {
    maxWidth: 464,
    position: "relative",
    p: 6,
    borderRadius: 4,
    bgcolor: "custom.white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [basicTheme.breakpoints.up("tablet")]: {
      p: 8,
      width: 464,
    },
  },
  closeButton: {
    position: "absolute",
    top: 16,
    right: 16,
    "&:hover": {
      bgcolor: "transparent",
    },
  },
  mainIcon: {
    bgcolor: "custom.beige",
    borderRadius: "100%",
    width: 80,
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mb: 4,
    [basicTheme.breakpoints.up("tablet")]: {
      width: 120,
      height: 120,
    },
  },
  mainCloseIcon: {
    bgcolor: "greenPalette.light",
    borderRadius: "100%",
    display: "flex",
    width: 80,
    height: 80,
    alignItems: "center",
    justifyContent: "center",
    mb: 4,
    [basicTheme.breakpoints.up("tablet")]: {
      width: 120,
      height: 120,
    },
  },
  title: {
    mb: 2,
    textAlign: "center",
  },
  subtitle: {
    width: "100%",
    textAlign: "center",
    mb: 4,
    [basicTheme.breakpoints.up("tablet")]: {
      width: 240,
    },
  },
  button: {
    width: "100%",
  },
};
