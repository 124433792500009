import React, { FC, useCallback, useState } from "react";

import { Button } from "../../Buttons/Button";
import { CrossIcon } from "../../icons/CrossIcon";
import { MessageIcon } from "../../icons/MessageIcon";
import { SuccessIcon } from "../../icons/SuccessIcon";
import { Box } from "../../ui/Box";
import { Dialog } from "../../ui/Dialog";
import { IconButton } from "../../ui/IconButton";
import { Typography } from "../../ui/Typography";

import { styles } from "./styles";

import verificationApi from "../../../service/userApi/verificationApi";

import { useBreakpoints } from "../../../hooks/useBreakpoints";

export interface VerifyPopupProps {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  title: string;
  subtitle: string;
  buttonText: string;
  buttonVariant: "close" | "send";
}

export const VerifyPopup: FC<VerifyPopupProps> = React.memo(
  ({ open, setOpen, title, subtitle, buttonText, buttonVariant }) => {
    const [sendSuccess, setSendSuccess] = useState(false);
    const { isUpTablet } = useBreakpoints();

    const verifyEmail = useCallback(async () => {
      try {
        const res = await verificationApi.verifyEmail();
        setSendSuccess(true);
      } catch (err) {
        console.error(err);
      }
    }, []);

    const handleButtonClick = () => {
      if (buttonVariant === "send") {
        if (sendSuccess) {
          setOpen(false);
        } else {
          verifyEmail();
        }
      } else {
        setOpen(false);
      }
    };

    return (
      <Dialog open={open} onClose={() => setOpen(false)} sx={styles.root}>
        <Box sx={styles.container}>
          <IconButton onClick={() => setOpen(false)} sx={styles.closeButton}>
            <CrossIcon sx={{ color: "custom.black" }} />
          </IconButton>
          <Box
            sx={
              buttonVariant === "close" || sendSuccess
                ? styles.mainCloseIcon
                : styles.mainIcon
            }
          >
            {buttonVariant === "close" || sendSuccess ? (
              <SuccessIcon size={"xl-ter"} />
            ) : (
              <MessageIcon size={isUpTablet ? "xl-sec" : "lg"} />
            )}
          </Box>
          <Typography
            variant={isUpTablet ? "h3" : "text20Medium"}
            sx={styles.title}
          >
            {buttonVariant === "close"
              ? title
              : !sendSuccess
              ? title
              : "Success!"}
          </Typography>
          <Typography sx={styles.subtitle}>
            {buttonVariant === "close"
              ? subtitle
              : !sendSuccess
              ? subtitle
              : "We have sent you an email for verification. Please check your inbox."}
          </Typography>
          <Button
            text={
              buttonVariant === "close"
                ? buttonText
                : !sendSuccess
                ? buttonText
                : "Ok"
            }
            onClick={handleButtonClick}
            sx={styles.button}
          />
        </Box>
      </Dialog>
    );
  }
);
