import { styles } from "./styles";

import { IconSize } from "../../../types/styles";

export const getIconSizeStyles = (size: IconSize) => {
  switch (size) {
    case "lg": {
      return styles.large;
    }
    case "lg-sec": {
      return styles.largeSecondary;
    }
    case "sm": {
      return styles.small;
    }
    case "md-sec": {
      return styles.mediumSecondary;
    }
    case "md-ter": {
      return styles.mediumTertiary;
    }
    case "xl": {
      return styles.huge;
    }
    case "xl-sec": {
      return styles.hugeSecondary;
    }
    case "xl-ter": {
      return styles.hugeTertiary;
    }
    default: {
      return styles.medium;
    }
  }
};
