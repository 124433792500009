import { basicTheme } from "../../../theme";
import { SxStyles } from "../../../types/styles";

export const styles: SxStyles = {
  small: {
    "&": {
      width: "14px",
      height: "14px",
    },
  },
  medium: {
    "&": {
      width: "24px",
      height: "24px",
    },
  },
  mediumSecondary: {
    "&": {
      width: "18px",
      height: "18px",
    },
  },
  mediumTertiary: {
    "&": {
      width: "20px",
      height: "20px",
    },
  },
  large: {
    "&": {
      width: "50px",
      height: "50px",
    },
  },
  largeSecondary: {
    "&": {
      width: "40px",
      height: "40px",
    },
  },
  huge: {
    width: 120,
    height: 120,
    [basicTheme.breakpoints.up("tablet")]: {
      width: 162,
      height: 162,
    },
  },
  hugeSecondary: {
    "&": {
      width: 74,
      height: 74,
    },
  },
  hugeTertiary: {
    "&": {
      width: 90,
      height: 90,
      [basicTheme.breakpoints.up("tablet")]: {
        width: 120,
        height: 120,
      },
    },
  },
};
