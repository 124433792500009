import { FC } from "react";

import { SvgIconProps } from "../../../types/styles";
import { SvgIcon } from "../../ui/SvgIcon";

export const AccountIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="7.5" r="2.5" stroke="#1B2E3C" strokeWidth="1.25" fill="none"/>
    <circle cx="10" cy="7.5" r="2.5" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" fill="none"/>
    <circle cx="10" cy="7.5" r="2.5" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" fill="none"/>
    <circle cx="10" cy="7.5" r="2.5" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" fill="none"/>
    <circle cx="9.99935" cy="9.99999" r="8.33334" stroke="#1B2E3C" strokeWidth="1.25" fill="none"/>
    <circle cx="9.99935" cy="9.99999" r="8.33334" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" fill="none"/>
    <circle cx="9.99935" cy="9.99999" r="8.33334" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" fill="none"/>
    <circle cx="9.99935" cy="9.99999" r="8.33334" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" fill="none"/>
    <path d="M14.974 16.6667C14.8414 14.2571 14.1037 12.5 9.99972 12.5C5.89576 12.5 5.15801 14.2571 5.02539 16.6667" stroke="#1B2E3C" strokeWidth="1.25" strokeLinecap="round" fill="none"/>
    <path d="M14.974 16.6667C14.8414 14.2571 14.1037 12.5 9.99972 12.5C5.89576 12.5 5.15801 14.2571 5.02539 16.6667" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" strokeLinecap="round" fill="none"/>
    <path d="M14.974 16.6667C14.8414 14.2571 14.1037 12.5 9.99972 12.5C5.89576 12.5 5.15801 14.2571 5.02539 16.6667" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" strokeLinecap="round" fill="none"/>
    <path d="M14.974 16.6667C14.8414 14.2571 14.1037 12.5 9.99972 12.5C5.89576 12.5 5.15801 14.2571 5.02539 16.6667" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" strokeLinecap="round" fill="none"/>
  </SvgIcon>
);
