import { FC } from "react";

import { Image } from "mui-image";

import logo from "../../assets/images/logo.svg";
import whiteLogo from "../../assets/images/whiteLogo.svg";

interface AppLogoProps {
  variant?: "color" | "white";
}

export const AppLogo: FC<AppLogoProps> = ({ variant = "color" }) =>
  variant === "color" ? (
    <Image alt="New York Stays logo" src={logo} duration={0} />
  ) : (
    <Image alt="New York Stays logo" src={whiteLogo} duration={0} />
  );
