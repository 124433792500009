import { basicTheme } from "../../../theme";
import { SxStyles } from "../../../types/styles";

export const styles: SxStyles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textDecoration: "none",
    py: 2,
    width: "100%",
    cursor: "pointer",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: 2,
    maxWidth: 244,
  },
};
