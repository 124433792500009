import { FC } from "react";

import { SvgIconProps } from "../../../types/styles";
import { SvgIcon } from "../../ui/SvgIcon";

export const ArrowRightIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.5 4.16666L12.5 9.99999L7.5 15.8333" stroke="#1B2E3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    <path d="M7.5 4.16666L12.5 9.99999L7.5 15.8333" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"  fill="none"/>
    <path d="M7.5 4.16666L12.5 9.99999L7.5 15.8333" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"  fill="none"/>
    <path d="M7.5 4.16666L12.5 9.99999L7.5 15.8333" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"  fill="none"/>
  </SvgIcon>
);
