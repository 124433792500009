import { FC, ReactNode } from "react";

import { ButtonProps as MuiButtonProps } from "@mui/material";
import { Button as MuiButton } from "../../ui/Button";
import { Typography } from "../../ui/Typography";

import { SxProps } from "@mui/material";
import { styles } from "./styles";

export interface ButtonProps extends MuiButtonProps {
  variantType?: "primary" | "secondary" | "white";
  text: string | ReactNode;
}

export const Button: FC<ButtonProps> = ({
  variantType = "primary",
  text,
  ...rest
}) => {
  const buttonStyle = (variantType: string): SxProps =>
    variantType === "primary"
      ? ({ ...styles.buttonPrimary, ...rest.sx } as SxProps)
      : variantType === "white"
      ? ({
          ...styles.buttonPrimary,
          ...styles.buttonWhite,
          ...rest.sx,
        } as SxProps)
      : ({ ...styles.button, ...rest.sx } as SxProps);
  return (
    <MuiButton {...rest} sx={buttonStyle(variantType)}>
      {typeof text === "string" ? (
        <Typography variant="textButton">{text}</Typography>
      ) : (
        text
      )}
    </MuiButton>
  );
};
