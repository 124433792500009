import { FC } from "react";

import { SvgIconProps } from "../../../types/styles";
import { SvgIcon } from "../../ui/SvgIcon";

export const RequestIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 3.33478C15.1459 3.34487 16.1275 3.42524 16.7678 4.06556C17.5 4.7978 17.5 5.97631 17.5 8.33333V13.3333C17.5 15.6904 17.5 16.8689 16.7678 17.6011C16.0355 18.3333 14.857 18.3333 12.5 18.3333H7.5C5.14298 18.3333 3.96447 18.3333 3.23223 17.6011C2.5 16.8689 2.5 15.6904 2.5 13.3333V8.33333C2.5 5.97631 2.5 4.7978 3.23223 4.06556C3.87255 3.42524 4.85415 3.34487 6.66667 3.33478"
      stroke="#1B2E3C"
      strokeWidth="1.25"
    />
    <path
      d="M13.3333 3.33478C15.1459 3.34487 16.1275 3.42524 16.7678 4.06556C17.5 4.7978 17.5 5.97631 17.5 8.33333V13.3333C17.5 15.6904 17.5 16.8689 16.7678 17.6011C16.0355 18.3333 14.857 18.3333 12.5 18.3333H7.5C5.14298 18.3333 3.96447 18.3333 3.23223 17.6011C2.5 16.8689 2.5 15.6904 2.5 13.3333V8.33333C2.5 5.97631 2.5 4.7978 3.23223 4.06556C3.87255 3.42524 4.85415 3.34487 6.66667 3.33478"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
    />
    <path
      d="M13.3333 3.33478C15.1459 3.34487 16.1275 3.42524 16.7678 4.06556C17.5 4.7978 17.5 5.97631 17.5 8.33333V13.3333C17.5 15.6904 17.5 16.8689 16.7678 17.6011C16.0355 18.3333 14.857 18.3333 12.5 18.3333H7.5C5.14298 18.3333 3.96447 18.3333 3.23223 17.6011C2.5 16.8689 2.5 15.6904 2.5 13.3333V8.33333C2.5 5.97631 2.5 4.7978 3.23223 4.06556C3.87255 3.42524 4.85415 3.34487 6.66667 3.33478"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
    />
    <path
      d="M13.3333 3.33478C15.1459 3.34487 16.1275 3.42524 16.7678 4.06556C17.5 4.7978 17.5 5.97631 17.5 8.33333V13.3333C17.5 15.6904 17.5 16.8689 16.7678 17.6011C16.0355 18.3333 14.857 18.3333 12.5 18.3333H7.5C5.14298 18.3333 3.96447 18.3333 3.23223 17.6011C2.5 16.8689 2.5 15.6904 2.5 13.3333V8.33333C2.5 5.97631 2.5 4.7978 3.23223 4.06556C3.87255 3.42524 4.85415 3.34487 6.66667 3.33478"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
    />
    <path
      d="M6.66602 11.6667H13.3327"
      stroke="#1B2E3C"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M6.66602 11.6667H13.3327"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M6.66602 11.6667H13.3327"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M6.66602 11.6667H13.3327"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M5.83398 8.75H14.1673"
      stroke="#1B2E3C"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M5.83398 8.75H14.1673"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M5.83398 8.75H14.1673"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M5.83398 8.75H14.1673"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M7.5 14.5833H12.5"
      stroke="#1B2E3C"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M7.5 14.5833H12.5"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M7.5 14.5833H12.5"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M7.5 14.5833H12.5"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M6.66602 2.91669C6.66602 2.22633 7.22566 1.66669 7.91602 1.66669H12.0827C12.773 1.66669 13.3327 2.22633 13.3327 2.91669V3.75002C13.3327 4.44038 12.773 5.00002 12.0827 5.00002H7.91602C7.22566 5.00002 6.66602 4.44038 6.66602 3.75002V2.91669Z"
      stroke="#1B2E3C"
      strokeWidth="1.25"
    />
    <path
      d="M6.66602 2.91669C6.66602 2.22633 7.22566 1.66669 7.91602 1.66669H12.0827C12.773 1.66669 13.3327 2.22633 13.3327 2.91669V3.75002C13.3327 4.44038 12.773 5.00002 12.0827 5.00002H7.91602C7.22566 5.00002 6.66602 4.44038 6.66602 3.75002V2.91669Z"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
    />
    <path
      d="M6.66602 2.91669C6.66602 2.22633 7.22566 1.66669 7.91602 1.66669H12.0827C12.773 1.66669 13.3327 2.22633 13.3327 2.91669V3.75002C13.3327 4.44038 12.773 5.00002 12.0827 5.00002H7.91602C7.22566 5.00002 6.66602 4.44038 6.66602 3.75002V2.91669Z"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
    />
    <path
      d="M6.66602 2.91669C6.66602 2.22633 7.22566 1.66669 7.91602 1.66669H12.0827C12.773 1.66669 13.3327 2.22633 13.3327 2.91669V3.75002C13.3327 4.44038 12.773 5.00002 12.0827 5.00002H7.91602C7.22566 5.00002 6.66602 4.44038 6.66602 3.75002V2.91669Z"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="1.25"
    />
  </SvgIcon>
);
