import { basicTheme } from "../../../theme";
import { SxStyles } from "../../../types/styles";

export const styles: SxStyles = {
  root: {
    height: 72,
    bgcolor: "custom.white",
    boxShadow: "0px 4px 16px 0px rgba(94, 94, 94, 0.10)",
    position: "fixed",
    top: 0,
    zIndex: 100,
    width: "100vw",
    py: 4,
    display: "flex",
    alignItems: "center",
    [basicTheme.breakpoints.up("tablet")]: {
      height: 91,
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  showHead: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  logo: {
    width: 147,
    height: 40,
    cursor: "pointer",
  },
  logoSecondary: {
    m: "auto",
    width: 147,
    height: 40,
  },
  userInfo: {
    display: "flex",
    columnGap: 6,
    alignItems: "center",
  },
  userInfoMenu: {
    display: "flex",
    columnGap: 2,
    alignItems: "center",
    cursor: "pointer",
  },
  userImg: {
    width: 40,
    height: 40,
  },
  headerDropDown: {
    bgcolor: "custom.white",
    borderRadius: 2,
    position: "absolute",
    boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.16)",
    right: 0,
    top: 70,
    p: 6,
    width: 312,
  },
  userImage: {
    width: 40,
    height: 40,
  },
};
